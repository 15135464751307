import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as dcbaConstants from 'src/app/utils/dcba.contstants';
import { AppLanguage } from 'src/app/enums/language.enum';
import { CountryId } from 'src/app/enums/country-id.enum';
import { TelcoId } from 'src/app/enums/telco-id.enum';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { SubscriptionCycle } from 'src/app/enums/subscription-cycle.enum';
import { UtilsService } from '../utils/utils.service';
import { apiBaseUrl } from 'src/app/utils/dcba.contstants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
/** Diract carrier billing service */
export class DcbaService {
  public headers: HttpHeaders;
  public apiUrl = 'http://thaiplay.funmunch.mobi/';
  public accessToken = '';
  public response: any;
  public url: string;

  constructor(
    private http: HttpClient,
    private localSvc: LocalStorageService,
    private utilsSvc: UtilsService,
    private router: Router
  ) {
    // this.url = localStorage.getItem('url')
    setTimeout(() => {
      this.getToken();
    }, 500);
    console.log('check-url', this.router.url);
    this.url = this.router.url;
  }

  getToken(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.accessToken = await this.getAccessToken();
      // console.log('this.accessToken', this.accessToken);
    });
  }

//   const pathLocation = apiBaseUrl + '?' +
//   'shortCode=' + '4541337' + '&' +
//   'CallBackUrl=' + encodeURI('http://localhost:9000/subscription/purchase');
// window.location.href = pathLocation;
// console.log('pathLocation', pathLocation);

  initiateRequest(keyword, shortCode): Promise<any> {
    console.log('keyword, shortCode', keyword, shortCode);
    return new Promise(async (resolve, reject) => {
      const pathLocation = apiBaseUrl + '?' +
        'Keyword=' + keyword  + '&' +
        'Shortcode=' + shortCode + '&' +
        'CallBackUrl=' + encodeURIComponent(environment.callbackUrl + 'subscription/purchase');
      window.location.href = pathLocation;
      console.log('pathLocation', pathLocation);

    });
  }

  GetHttp(apiName: string, params: HttpParams): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(dcbaConstants.apiBaseUrl + apiName, { params: params }).subscribe(
        (response: any) => {
          console.log(response);
          switch (response) {
            case '200':
              resolve(response);
              break;
            case '400':
              reject('Missing Param');
              break;
            case '401':
              reject('Invalid Access Token');
              break;
            case '402':
              reject('Invalid language');
              break;
            case '403':
              reject('Invalid Country');
              break;
            case '404':
              reject('Service Not Found');
              break;
            case '405':
              reject('Invalid Msisdn');
              break;
            case '405':
              reject('Invalid Telco');
              break;
            case '407':
              reject('Invalid Price');
              break;
            case '408':
              reject('Invalid  subscription cycle');
              break;
            case '410':
              reject('Invalid RefId (Exceed length supported)');
              break;
            case '411':
              reject('Invalid TransactionId');
              break;
            case '412':
              reject('Subscription Exist');
              break;
            case '500':
              reject('Server Error');
              break;
            default:
              resolve(response);
              break;
          }
        },
        error => reject(error.message)
      );
    });
  }

  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.localSvc.getData('accessToken').then(accessToken => {
          resolve(accessToken);
        }).catch(async err => {
          const uuid: string = await this.getRefId();
          const keyword = localStorage.getItem('thwall-keyword');
          const params = new HttpParams()
            .set('uu_id', uuid)
            .set('keyword', keyword)
            .set('url', this.url);
          this.http.get('http://thaiplay.funmunch.mobi/md5.php', {params}).subscribe(
            (next: any) => {
              console.log('next', next);
              if (next.status === true) {
                const accessToken = next.token;
                this.localSvc.setData('accessToken', accessToken).then(() => {
                    resolve(accessToken);
                  }).catch((error) => {
                    reject(error);
                  });
              } else {
                reject(next.message);
              }
            },
            (httpError: any) => {
              reject('Something went wrong');
            }
          );
        });
    });
  }

  getRefId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.localSvc.getData('newrefId')
        .then(refId => {
          localStorage.setItem('newrefId', refId);
          const keyword = localStorage.getItem('thwall-keyword');
          const params = new HttpParams()
            .set('uu_id', refId)
            .set('keyword', keyword)
            .set('url', this.url);
          this.http.get('http://thaiplay.funmunch.mobi/md5.php', { params}).subscribe((res: any) => {
            if (res.status) {
              localStorage.setItem('AccessToken', res.token);
            }
            // console.log('res', res);
          });
          resolve(refId);
        })
        .catch(err => {
          const refId = this.utilsSvc.getUUID();
          localStorage.setItem('newrefId', refId);
          const keyword = localStorage.getItem('thwall-keyword');
          const params = new HttpParams()
            .set('uu_id', refId)
            .set('keyword', keyword)
            .set('url', this.url);
          this.http.get('http://thaiplay.funmunch.mobi/md5.php', { params }).subscribe((res: any) => {
            if (res.status) {
              localStorage.setItem('AccessToken', res.token);
            }
            // console.log('res', res);
          });
          this.localSvc.setData('refId', refId).then(() => {
            resolve(refId);
          }).catch(() => { });
        });
    });
  }

  purchaseWithoutPin(refid, tranId, telcoId, msisdn) {
    return this.http.get<any>(this.apiUrl + 'purchase-request.php' + '?RefID=' + refid + '&TransactionId=' + tranId + '&TelcoId=' + telcoId + '&Msisdn=' + msisdn +'&token=' + this.accessToken , { observe: 'response' });
  }

  purchaseWithPin(refid, tranId, msisdn, telcoId, pin) {
    return this.http.get<any>(this.apiUrl + 'purchase-request.php' + '?RefID=' + refid + '&TransactionId=' + tranId + '&Msisdn=' + msisdn + '&TelcoId=' + telcoId + '&pin=' + pin +'&token=' + this.accessToken , { observe: 'response' });
  }

  purchaseDirectly(refid, tranId, msisdn, telcoId) {
    const lang = localStorage.getItem('language');
    return this.http.get<any>(this.apiUrl + 'purchase-request.php' + '?RefID=' + refid + '&TransactionId=' + tranId + '&Msisdn=' + msisdn + '&TelcoId=' + telcoId +'&token=' + this.accessToken + '&Language=' + lang , { observe: 'response' });
  }

  requestPin(refid, tranId) {
    return this.http.get<any>(this.apiUrl + 'pin-request.php' + '?RefID=' + refid + '&TransactionId=' + tranId +'&token=' + this.accessToken , { observe: 'response' });
  }

  requestPinForMobileNo(refid, tranId, telcoId, msisdn) {
    const lang = localStorage.getItem('language');
    this.accessToken = localStorage.getItem('AccessToken');
    return this.http.get<any>(this.apiUrl + 'pin-request.php' + '?RefID=' + refid + '&TransactionId=' + tranId + '&TelcoId=' + telcoId + '&Msisdn=' + msisdn + '&Language=' + lang + '&token=' + this.accessToken  , { observe: 'response' });
  }

  requestResendPin(refid, tranId) {
    const lang = localStorage.getItem('language');
    return this.http.get<any>(this.apiUrl + 'pin-request.php' + '?RefID=' + refid + '&TransactionId=' + tranId  + '&Language=' + lang + '&token=' + this.accessToken, { observe: 'response' });
  }

  unsubscribeService(refid, username, telcoId) {
    return this.http.get<any>(this.apiUrl + 'unsubscribe.php'+ '?RefID=' + refid  + '&username=' + username + '&TelcoId=' + telcoId + '&token=' + this.accessToken, { observe: 'response' });
  }

  setSuccessDataInDb(refid, tranId, telcoId, msisdn) {
    const lang = localStorage.getItem('language');
    return this.http.get<any>(this.apiUrl + 'success-subscribe.php'+ '?RefID=' + refid  + '&TransactionId=' + tranId + '&TelcoId=' + telcoId  + '&Msisdn=' + msisdn + '&token=' + this.accessToken + '&Language=' + lang , { observe: 'response' });
  }

  UnsubscribeDataInDb(msisdn) {
    return this.http.get<any>(this.apiUrl + 'success-unsubscribe.php' + '?Msisdn=' + msisdn, { observe: 'response' });
  }

  setSuccessData(data) {
    this.response = data;
  }

  getSuccessData() {
    return this.response;
  }

  sendSavedDataOnSucceess(postData) {
    const refId = localStorage.getItem('newrefId');
    const token = localStorage.getItem('AccessToken');
     return this.http.get<any>(this.apiUrl + 'mo-wap.php' + '?Msisdn=' + postData.Msisdn + '&Telcoid=' + postData.Telcoid + '&Shortcode=' + postData.Shortcode + '&Keyword=' + postData.Keyword + '&RefId=' + refId + '&AuthToken=' + token, { observe: 'response' });
  }

  saveUserDetails(clickId, dateTime, user_agent, xmlHttpReq, token, msisdn, pubId) {
    return this.http.get<any>(this.apiUrl + 'useragent.php' + '?clickid=' + clickId + '&lp_time=' + dateTime + '&user_agent=' + user_agent + '&x_requested_with=' + xmlHttpReq + '&token=' + token + '&msisdn=' + msisdn + '&pub_id=' + pubId, { observe: 'response' });
  }

}
