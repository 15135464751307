import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
  /**
   * Get aplpha numeric UUID
   */
  public getUUID(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  /**
   * Get uts date time (+8 hours) in yyyymmddhhmmss
   */
  public getUTCTime() {
    const d = new Date();
    d.setTime(d.getTime() + (8 * 60 * 60 * 1000));
    const dateStr =
      this.preFixZero(d.getUTCFullYear()) +
      this.preFixZero(d.getUTCMonth() + 1) +
      this.preFixZero(d.getUTCDate()) +
      this.preFixZero(d.getUTCHours()) +
      this.preFixZero(d.getUTCMinutes());
    return dateStr;
  }

  private preFixZero(num: number): string {
    return num >= 10 ? num.toString() : '0' + num.toString();
  }

}
