import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  localStorage: any;

  constructor() { }

  /**
   * Get data from local storage
   * @param key Name of key in of which you want to get data
   * @param inJsonParsed If true data will be parsed into JSON Object before return, else not
   */
  getData(key: string, inJsonParsed: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      let localData = this.localStorage.getItem(key);
      if (localData) {
        if (inJsonParsed) {
          try {
            localData = JSON.parse(localData);
          } catch (err) {
            reject(err);
          }
        }
        resolve(localData);
      } else {
        reject('No data found');
      }
    });
  }

  /**
   * Save data in local storage
   * @param key Name of key in of which you want to save data
   * @param data Data to be saved
   * @param inJsonString If true data will be parsed into JSON String before save, else not
   */
  setData(key: string, data: any, inJsonString: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (key && data) {
        let _data = data;
        if (inJsonString === true) {
          try {
            _data = JSON.stringify(data);
          } catch (err) {
            reject(err);
          }
        }
        this.localStorage.setItem(key, _data);
        resolve(true);
      } else {
        reject('No data to save');
      }
    });
  }
}
