
export const apiBaseUrl = 'http://mis.etracker.cc/THWAP/MsisdnForwarding.aspx';
export const userName = 'MorisMedia';
export const serviceName = 'FunMunch';
export const password = '8Wfs(dL6';
export const phoneNumber = '9813085210';

export const apiParams = {
    refId: 'refId',
    transactionId: 'transactionId',
    telcoId: 'telcoId',
    msisdn: 'msisdn'
};

