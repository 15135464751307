import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuccessComponent } from './success/success.component';


const routes: Routes = [
  {
    path: 'success',
    component: SuccessComponent
  },
  {
    path: 'subscription',
    loadChildren: './modules/subscription/subscription.module#SubscriptionModule',
  },
  {
    path: '',
    loadChildren: './modules/portal/portal.module#PortalModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
